import { Routes, Route } from 'react-router-dom'
import Index from 'views/Index'
import PanelLayout from 'layouts/Panel'
import PureLayout from 'layouts/PureLayout'
import NotFoundPage from 'views/NotFoundPage'

import RegistriesListPage from 'views/panel/Registries/List'
import RegistryViewPage from 'views/panel/Registries/View'
import RegistryCreationPage from 'views/panel/Registries/Create'
import RegistryViewQuotesPage from 'views/panel/Registries/View/Quotes'
import RegistryViewHistoryPage from 'views/panel/Registries/View/History'
import RegistryViewGeneralPage from 'views/panel/Registries/View/General'
import RegistryViewShipmentsPage from 'views/panel/Registries/View/Shipments'
import RegistryViewDocumentsPage from 'views/panel/Registries/View/Documents'
import RegistryViewFinancialDetailsPage from 'views/panel/Registries/View/FinancialDetails'
import RegistryViewFinancialBalancePage from 'views/panel/Registries/View/FinancialBalance'
import RegistryContactPage from 'views/panel/Registries/View/Contact'
import RegistryContactMailsPage from 'views/panel/Registries/View/Contact/Mails'
import RegistryContactWhatsappPage from 'views/panel/Registries/View/Contact/Whatsapp'
import RegistryContactGeneralPage from 'views/panel/Registries/View/Contact/General'

import ShipmentLayout from 'layouts/Shipment'
import ShipmentsListPage from 'views/panel/Shipments/List'
import ShipmentViewCargoPage from 'views/panel/Shipments/View/Cargo'
import ShipmentViewContainerPage from 'views/panel/Shipments/View/Container'
import ShipmentViewFinancePage from 'views/panel/Shipments/View/Finance'
import ShipmentViewTrackingPage from 'views/panel/Shipments/View/Tracking'
import ShipmentViewInvoicesPage from 'views/panel/Shipments/View/Invoices'
import ShipmentViewNotesPage from 'views/panel/Shipments/View/Notes'
import ShipmentViewGeneralPage from 'views/panel/Shipments/View/General'
import ShipmentViewHouseShipmentsPage from 'views/panel/Shipments/View/HouseShipments'
import ShipmentViewHistoryPage from 'views/panel/Shipments/View/History'
import ShipmentViewActionsPage from 'views/panel/Shipments/View/Actions'
import ShipmentViewDocumentPage from 'views/panel/Shipments/View/Documents'
import ShipmentViewEntrancesPage from 'views/panel/Shipments/View/Entrances'
import ShipmentViewEntranceCreationPage from 'views/panel/Shipments/View/Entrances/Create'
import ShipmentViewEditRegistriesPage from 'views/panel/Shipments/View/EditRegistries'
import ShipmentViewCargoPickupPage from 'views/panel/Shipments/View/CargoPickup'
import ShipmentViewFirstContactPage from 'components/Shipments/View/FirstContact'
import ShipmentViewAirFreightBookingPage from 'components/Shipments/View/AirFreightBooking'
import ShipmentViewEntrancesViewPage from 'views/panel/Shipments/View/Entrances/View'
import ShipmentCreationMasterPage from 'views/panel/Shipments/Create/Master'
import ShipmentViewSharesPage from 'views/panel/Shipments/View/Shares'
import ShipmentViewDocumentEmailActionPage from 'views/panel/Shipments/View/DocumentEmailAction'
import ShipmentViewEditFinancePage from 'views/panel/Shipments/View/EditFinance'

import ShipmentCreationPage from 'views/panel/Shipments/Create'
import ShipmentCreationCargoPage from 'views/panel/Shipments/Create/Cargo'
import ShipmentCreationSummaryPage from 'views/panel/Shipments/Create/Summary'
import ShipmentCreationRoutingPage from 'views/panel/Shipments/Create/Routing'

import RatesListPage from 'views/panel/Rates/List'
import RateCreationPage from 'views/panel/Rates/Create'
import RateViewPage from 'views/panel/Rates/view'
import RateViewGeneralPage from 'views/panel/Rates/view/General'
import RateSurchargesPage from 'views/panel/Rates/view/Surcharges'
import RatesRecordsPage from 'views/panel/Rates/view/Records'
import RateRecordsCreationPage from 'views/panel/Rates/view/Records/Create'

import ShipmentEntrancePage from 'views/panel/Shipments/Entrance'

import InvoicesListPage from 'views/panel/Invoices/List'

import Unauthorized from 'views/Unauthorized'

import ConsolidationsListPage from 'views/panel/Consolidations/List'
import ConsolidationLayout from 'layouts/Consolidation'
import ConsolidationViewCargoPage from 'views/panel/Consolidations/View/Cargo'
import ConsolidationViewGeneralPage from 'views/panel/Consolidations/View/General'
import ConsolidationViewShipmentsPage from 'views/panel/Consolidations/View/Shipments'
import ConsolidationViewAirwaybillsPage from 'views/panel/Consolidations/View/Airwaybills'
import ConsolidationViewHistoryPage from 'views/panel/Consolidations/View/History'
import ConsolidationViewNotesPage from 'views/panel/Consolidations/View/Notes'
import ConsolidationViewActionsPage from 'views/panel/Consolidations/View/Actions'
import ConsolidationViewEntrancesPage from 'views/panel/Consolidations/View/Entrances'
import ConsolidationViewDocumentPage from 'views/panel/Consolidations/View/Documents'
import ConsolidationCreationPage from 'views/panel/Consolidations/Create'
import ConsolidationCreationAgentsPage from 'views/panel/Consolidations/Create/Agents'
import ConsolidationCreationGeneralPage from 'views/panel/Consolidations/Create/General'
import ConsolidationCreationShipmentsPage from 'views/panel/Consolidations/Create/Shipments'
import ConsolidationViewCargoPickupPage from 'views/panel/Consolidations/View/CargoPickup'
import ConsolidationViewTrackingPage from 'views/panel/Consolidations/View/Tracking'
import ConsolidationViewAddLocalisationPage from 'views/panel/Consolidations/View/AddLocalisation'
import ConsolidationViewSharesPage from 'views/panel/Consolidations/View/Shares'
import ConsolidationViewEditGeneralInformationPage from 'views/panel/Consolidations/View/EditGeneralInformation'
import ConsolidationViewFinancePage from 'views/panel/Consolidations/View/Finance'
import ConsolidationViewEditFinancePage from 'views/panel/Consolidations/View/EditFinance'

import DocumentsListPage from 'views/panel/Documents'

import RedirectToAccount from 'components/RedirectToAccount'
import AddLocalisationPublicPage from 'views/AddLocalisation'
import PublicPagesLayout from 'layouts/PublicPages'

// Features
import { ViewDocument } from 'features/documents'
import {
  IssueShipmentInvoice,
  ViewInvoiceHistory,
  ViewInvoiceRows,
  ViewInvoiceGeneral,
  ViewInvoice,
  ViewPDFAsso,
  CreatePassiveInvoice,
  CreateActiveInvoice,
  EditActiveInvoice,
} from 'features/invoice'
import { DocumentTypesCreationPage, DocumentTypesListPage } from 'features/documentTypes'
import { BanksCreationPage, BanksListPage } from 'features/banks'
import { PaymentTermsCreationPage, PaymentTermsListPage } from 'features/paymentTerms'
import { ServiceCodesListPage, ServiceCodeCreationPage } from 'features/serviceCodes'
import {
  AirWaybillStockCreationPage,
  AirWaybillStockListPage,
  AirWaybillStockViewPage,
} from 'features/AirWaybillStock'
import { VehicleTypeCreationPage, VehicleTypeListPage } from 'features/vehicleType'

import {
  MarginCalculatorListPage,
  MarginCalculatorCreationPage,
  MarginCalculatorViewPage,
  MarginCalculatorViewGeneralPage,
  MarginCalculatorViewBracketsPage,
} from 'features/marginCalculator'
import {
  LinesListPage,
  LineCreateEditPage,
  LineViewPage,
  LineViewGeneralTab,
  LineViewRoutesTab,
  LineRouteCreateEditPage,
} from 'features/lines'

import { AirWaybillListPage } from 'features/AirWaybill'
import { ViewDashboardPage } from 'features/dashboard'
import { UsersCreationPage, UsersListPage } from 'features/users'
import { CurrencyListPage, CurrencyCreationPage } from 'features/currency'
import CommercialInvoiceCreateEditPage from 'views/panel/Shipments/View/CommercialInvoice'

import AirlinesListPage from 'features/airlines/views/AirlinesListPage'
import AirlineViewPage from 'features/airlines/views/AirlineViewPage'
import AirlineGeneralInformationTab from 'features/airlines/components/View/GeneralInformation'
import AirlineGroundHandlersTab from 'features/airlines/components/View/GroundHandlers'

// Customs declarations
import CustomsDeclarationCreateEditPage from 'features/shipments/CustomsDeclaration/Create'

export default function AppRoutes() {
  return (
    <Routes>
      <Route path={'/'} element={<Index />} />
      <Route path="/login" element={<RedirectToAccount redirectTo="login" />} />
      <Route path="/register" element={<RedirectToAccount redirectTo="register" />} />
      <Route path="/forget-password" element={<RedirectToAccount redirectTo="forget-password" />} />

      <Route path="/panel">
        <Route element={<PanelLayout />}>
          <Route path="" element={<ViewDashboardPage />} />

          <Route path="registries" element={<RegistriesListPage />} />

          <Route path="registries/:registryId/:creatorId?" element={<RegistryViewPage />}>
            <Route index element={<RegistryViewGeneralPage />} />
            <Route path="quotes" element={<RegistryViewQuotesPage />} />
            <Route path="history" element={<RegistryViewHistoryPage />} />
            <Route path="documents" element={<RegistryViewDocumentsPage />} />
            <Route path="shipments" element={<RegistryViewShipmentsPage />} />
            <Route path="financial" element={<RegistryViewFinancialDetailsPage />} />
            <Route path="financial-balance" element={<RegistryViewFinancialBalancePage />} />
          </Route>

          <Route path="airlines" element={<AirlinesListPage />} />
          <Route path="airlines/:airlineId" element={<AirlineViewPage />}>
            <Route index element={<AirlineGeneralInformationTab />} />
            <Route path="ground-handlers" element={<AirlineGroundHandlersTab />} />
          </Route>

          <Route path="shipments" element={<ShipmentsListPage entity="shipment" />} />

          {[
            { path: 'active-invoices', type: 'active' },
            { path: 'passive-invoices', type: 'passive' },
            { path: 'buffer-invoices', type: 'buffer' },
          ].map((r) => (
            <Route key={r.type}>
              <Route path={r.path} element={<InvoicesListPage type={r.type} />} />

              <Route path={`${r.path}/:invoiceId`} element={<ViewInvoice type={r.type} />}>
                <Route index element={<ViewInvoiceGeneral />} />
                <Route path="rows" element={<ViewInvoiceRows />} />
                <Route path="history" element={<ViewInvoiceHistory />} />
              </Route>
            </Route>
          ))}

          <Route path="quotations" element={<ShipmentsListPage entity="quotation" />} />
          <Route path="users" element={<UsersListPage />} />

          <Route path="rates" element={<RatesListPage />} />

          <Route path="rates/:rateId" element={<RateViewPage />}>
            <Route index element={<RateViewGeneralPage />} />
            <Route path="surcharges" element={<RateSurchargesPage />} />
            <Route path="records" element={<RatesRecordsPage />} />
          </Route>

          <Route path="margin-calculator/:marginId" element={<MarginCalculatorViewPage />}>
            <Route index element={<MarginCalculatorViewGeneralPage />} />
            <Route path="brackets" element={<MarginCalculatorViewBracketsPage />} />
          </Route>

          <Route path="consolidations" element={<ConsolidationsListPage />} />

          <Route path="documents">
            <Route index element={<DocumentsListPage />} />
          </Route>

          <Route path="document-types" element={<DocumentTypesListPage />} />
          <Route path="banks" element={<BanksListPage />} />
          <Route path="payment-terms" element={<PaymentTermsListPage />} />
          <Route path="service-codes" element={<ServiceCodesListPage />} />
          <Route path="vehicle-types" element={<VehicleTypeListPage />} />

          {/* TODO: Put all the routes for sales machine here */}
          <Route path="margin-calculator" element={<MarginCalculatorListPage />} />

          <Route path="sales-machine">
            <Route path="lines">
              <Route index element={<LinesListPage />} />
              <Route path=":lineId" element={<LineViewPage />}>
                <Route index element={<LineViewGeneralTab />} />
                <Route path="routes" element={<LineViewRoutesTab />} />
              </Route>
            </Route>
          </Route>

          <Route path="currencies" element={<CurrencyListPage />} />
          <Route path="airwaybills" element={<AirWaybillListPage />} />

          <Route path="airwaybill-stock" element={<AirWaybillStockListPage />} />
          <Route path="airwaybill-stock/:stockId" element={<AirWaybillStockViewPage />} />
        </Route>

        <Route path="shipments/:shipmentId" element={<ShipmentLayout />}>
          <Route index element={<ShipmentViewGeneralPage />} />
          <Route path="house-shipments" element={<ShipmentViewHouseShipmentsPage />} />
          <Route path="cargo-details" element={<ShipmentViewCargoPage />} />
          <Route path="container-details" element={<ShipmentViewContainerPage />} />
          <Route path="finance" element={<ShipmentViewFinancePage />} />
          <Route path="tracking" element={<ShipmentViewTrackingPage />} />
          <Route path="active-invoices" element={<ShipmentViewInvoicesPage isActive />} />
          <Route path="passive-invoices" element={<ShipmentViewInvoicesPage />} />
          <Route path="entrances" element={<ShipmentViewEntrancesPage />} />
          <Route path="notes" element={<ShipmentViewNotesPage />} />
          <Route path="history" element={<ShipmentViewHistoryPage />} />
          <Route path="documents" element={<ShipmentViewDocumentPage />} />
          <Route path="shares" element={<ShipmentViewSharesPage />} />
          <Route path="actions" element={<ShipmentViewActionsPage />} />
        </Route>

        <Route path="quotations/:quotationId" element={<ShipmentLayout />}>
          <Route index element={<ShipmentViewGeneralPage />} />
          <Route path="cargo-details" element={<ShipmentViewCargoPage />} />
          {/* <Route path="container-details" element={<ShipmentViewContainerPage />} /> */}
          <Route path="finance" element={<ShipmentViewFinancePage />} />
          <Route path="notes" element={<ShipmentViewNotesPage />} />
          <Route path="history" element={<ShipmentViewHistoryPage />} />
          <Route path="documents" element={<ShipmentViewDocumentPage />} />
        </Route>

        <Route path="consolidations/:consolidationId" element={<ConsolidationLayout />}>
          <Route index element={<ConsolidationViewGeneralPage />} />
          <Route path="shipments" element={<ConsolidationViewShipmentsPage />} />
          <Route path="awb" element={<ConsolidationViewAirwaybillsPage />} />
          <Route path="cargo-details" element={<ConsolidationViewCargoPage />} />
          <Route path="finance" element={<ConsolidationViewFinancePage />} />
          <Route path="tracking" element={<ConsolidationViewTrackingPage />} />
          <Route path="notes" element={<ConsolidationViewNotesPage />} />
          <Route path="history" element={<ConsolidationViewHistoryPage />} />
          <Route path="shares" element={<ConsolidationViewSharesPage />} />
          <Route path="actions" element={<ConsolidationViewActionsPage />} />
          <Route path="documents" element={<ConsolidationViewDocumentPage />} />
        </Route>

        <Route path="buffer-invoices/:invoiceId/pdf-asso" element={<ViewPDFAsso />} />

        <Route element={<PureLayout />}>
          <Route path="registries/create" element={<RegistryCreationPage />} />
          <Route
            path="registries/:registryId/:creatorId?/edit"
            element={<RegistryCreationPage />}
          />
          <Route
            path="registries/:registryId/:creatorId?/contact/:contactId"
            element={<RegistryContactPage />}
          >
            <Route index element={<RegistryContactGeneralPage />} />
            <Route path="mails" element={<RegistryContactMailsPage />} />
            <Route path="whatsapp" element={<RegistryContactWhatsappPage />} />
          </Route>
          {[
            { entity: 'shipment', flagStatus: 2, path: 'shipments/create' },
            {
              flagStatus: 2,
              entity: 'shipment',
              path: 'shipments/:shipmentId/edit',
            },
            {
              entity: 'quotation',
              flagStatus: 0,
              path: 'quotations/create',
            },
            {
              flagStatus: 0,
              entity: 'quotation',
              path: 'quotations/:quotationId/edit',
            },
          ].map((r, i) => (
            <Route key={i} path={r.path} element={<ShipmentCreationPage entity={r.entity} />}>
              <Route index element={<ShipmentCreationRoutingPage entity={r.entity} />} />
              <Route path="cargo" element={<ShipmentCreationCargoPage />} />
              <Route
                path="summary"
                element={
                  <ShipmentCreationSummaryPage entity={r.entity} flagStatus={r.flagStatus} />
                }
              />
            </Route>
          ))}
          <Route path="shipments/create-master" element={<ShipmentCreationMasterPage />} />
          <Route
            path="shipments/:shipmentId/edit-master"
            element={<ShipmentCreationMasterPage />}
          />
          <Route
            path="shipments/:shipmentId/entrances/create"
            element={<ShipmentViewEntranceCreationPage />}
          />
          <Route
            path="shipments/entrances/:entranceId"
            element={<ShipmentViewEntrancesViewPage />}
          />
          <Route path="shipments/:shipmentId/entrances/upload" element={<ShipmentEntrancePage />} />
          {/* below route deprecated, use above route */}
          <Route path="shipments/entrance/:shipmentId" element={<ShipmentEntrancePage />} />
          <Route
            path="shipments/:shipmentId/edit-registries"
            element={<ShipmentViewEditRegistriesPage />}
          />
          <Route
            path="shipments/:shipmentId/cargo-collection/:documentId?"
            element={<ShipmentViewCargoPickupPage />}
          />
          <Route
            path="shipments/:shipmentId/cargo-delivery"
            element={<ShipmentViewCargoPickupPage isDelivery />}
          />
          <Route
            path="shipments/:shipmentId/first-contact"
            element={<ShipmentViewFirstContactPage />}
          />
          <Route
            path="shipments/:shipmentId/airfreight-booking"
            element={<ShipmentViewAirFreightBookingPage />}
          />
          <Route
            path="shipments/:shipmentId/mail-document"
            element={<ShipmentViewDocumentEmailActionPage />}
          />
          <Route
            path="shipments/:shipmentId/active-invoices/create"
            element={<IssueShipmentInvoice />}
          />
          <Route
            path="shipments/:shipmentId/finance/edit"
            element={<ShipmentViewEditFinancePage />}
          />
          <Route
            path="shipments/:shipmentId/commercial-invoices/create"
            element={<CommercialInvoiceCreateEditPage />}
          />
          <Route
            path="shipments/:shipmentId/commercial-invoices/:invoiceId/edit"
            element={<CommercialInvoiceCreateEditPage />}
          />
          <Route
            path="shipments/:shipmentId/customs-declarations/create"
            element={<CustomsDeclarationCreateEditPage />}
          />
          <Route
            path="shipments/:shipmentId/customs-declarations/:declarationId/edit"
            element={<CustomsDeclarationCreateEditPage />}
          />

          <Route
            path="quotations/:quotationId/finance/edit"
            element={<ShipmentViewEditFinancePage />}
          />

          <Route path="active-invoices/:invoiceId/edit" element={<EditActiveInvoice />} />
          <Route
            path="active-invoices/:invoiceId/credit-note"
            element={<EditActiveInvoice isCreditNote />}
          />
          <Route path="rates/:rateId/records/create" element={<RateRecordsCreationPage />} />
          <Route
            path="rates/:rateId/records/:recordId/edit"
            element={<RateRecordsCreationPage />}
          />
          <Route path="rates/create" element={<RateCreationPage />} />
          <Route path="rates/:rateId/edit" element={<RateCreationPage />} />
          <Route
            path="consolidations/:consolidationId/loading-management/:entranceId?"
            element={<ConsolidationViewEntrancesPage />}
          />
          {[
            { entity: 'consolidation', path: 'consolidations/document' },
            { entity: 'shipment', path: 'shipments/document' },
          ].map(({ entity, path }) => (
            <Route key={entity}>
              <Route
                path={`${path}/:documentKey/:documentId/:isBlank?`}
                element={<ViewDocument entity={entity} />}
              />
              <Route
                path={`${path}/:documentKey/:documentId/edit`}
                element={<ViewDocument entity={entity} isEditing />}
              />
            </Route>
          ))}
          {[
            { path: 'consolidations/create' },
            {
              path: 'consolidations/:consolidationId/edit',
            },
          ].map((item, i) => (
            <Route key={i} path={item.path} element={<ConsolidationCreationPage />}>
              <Route index element={<ConsolidationCreationGeneralPage />} />
              <Route path="agents" element={<ConsolidationCreationAgentsPage />} />
              <Route path="shipments" element={<ConsolidationCreationShipmentsPage />} />
            </Route>
          ))}
          <Route
            path="consolidations/:consolidationId/cargo-collection"
            element={<ConsolidationViewCargoPickupPage />}
          />
          <Route
            path="consolidations/:consolidationId/add-localisation"
            element={<ConsolidationViewAddLocalisationPage />}
          />
          <Route
            path="consolidations/:consolidationId/edit-general-information"
            element={<ConsolidationViewEditGeneralInformationPage />}
          />
          <Route
            path="consolidations/:consolidationId/finance/edit"
            element={<ConsolidationViewEditFinancePage />}
          />
          <Route path="passive-invoices/create" element={<CreatePassiveInvoice />} />
          <Route path="active-invoices/create" element={<CreateActiveInvoice />} />
          <Route path="document-types/create" element={<DocumentTypesCreationPage />} />
          <Route path="document-types/:typeId/edit" element={<DocumentTypesCreationPage />} />
          <Route path="banks/create" element={<BanksCreationPage />} />
          <Route path="banks/:bankId/edit" element={<BanksCreationPage />} />
          <Route path="vehicle-types/create" element={<VehicleTypeCreationPage />} />
          <Route path="vehicle-types/:typeId/edit" element={<VehicleTypeCreationPage />} />
          <Route path="payment-terms/create" element={<PaymentTermsCreationPage />} />
          <Route path="payment-terms/:codeId/edit" element={<PaymentTermsCreationPage />} />
          <Route path="service-codes/create" element={<ServiceCodeCreationPage />} />
          <Route path="service-codes/:serviceCode/edit" element={<ServiceCodeCreationPage />} />
          <Route path="margin-calculator/create" element={<MarginCalculatorCreationPage />} />
          <Route
            path="margin-calculator/:marginId/edit"
            element={<MarginCalculatorCreationPage />}
          />

          <Route path="sales-machine">
            <Route path="lines">
              <Route path="create" element={<LineCreateEditPage />} />
              <Route path=":lineId/edit" element={<LineCreateEditPage />} />

              <Route path=":lineId/routes/create" element={<LineRouteCreateEditPage />} />
              <Route path=":lineId/routes/:routeId/edit" element={<LineRouteCreateEditPage />} />
            </Route>
          </Route>

          <Route path="passive-invoices/:invoiceId/edit" element={<CreatePassiveInvoice />} />

          <Route path="users/create" element={<UsersCreationPage />} />
          <Route path="users/:userId/edit" element={<UsersCreationPage />} />
          <Route path="currencies/create" element={<CurrencyCreationPage />} />
          <Route path="currencies/:currencyCode/edit" element={<CurrencyCreationPage />} />
          <Route path="airwaybill-stock/create" element={<AirWaybillStockCreationPage />} />
        </Route>
      </Route>

      <Route element={<PublicPagesLayout />}>
        <Route
          path="consolidations/add-localisation/:consolidationId"
          element={<AddLocalisationPublicPage />}
        />
      </Route>

      <Route path="unauthorized" element={<Unauthorized />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
