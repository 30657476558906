import { useCallback, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

//Api
import { exportData } from 'api/exports'
import { getShipments } from 'api/shipments'
import { reportPerformance } from 'api/registries'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Contexts
import { useApp } from 'contexts/AppProvider'
import { useAuth } from 'contexts/AuthProvider'

//Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

//Components
import { shipmentsFilters } from 'components/Shipments/List/filters'
import { generateShipmentsColumns } from 'components/Shipments/List/columns'
import ShipmentsFilterFields from 'components/Shipments/List/FilterFields'
import ShipmentCreationSelectTypeModal from 'components/Shipments/Create/SelectTypeModal'
import ShipmentsDeleteModal from 'components/Shipments/View/DeleteModal'
import ShipmentsCancelModal from 'components/Shipments/View/CancelModal'

const ShipmentsListPage = ({ entity }) => {
  const navigate = useNavigate()
  const { setIsExportExcel } = useApp()
  const { isWarehouseUser, currentUser } = useAuth()
  const [isOpenNewShipmentModal, newShipmentModalHandlers] = useDisclosure()

  const [isOpenDeleteShipmentModal, deleteShipmentModalHandler] = useDisclosure('delete')
  const [isOpenCancelShipmentModal, cancelShipmentModalHandler] = useDisclosure('cancel')

  const isShipment = useMemo(() => !!(entity === 'shipment'), [entity])
  const pageTitle = useMemo(() => (isShipment ? 'Shipments' : 'Quotations'), [isShipment])

  const handleDoubleClick = (e, item) => {
    navigate(`/panel/${entity}s/${item.id_auto}`)
  }

  const openDeleteModal = (shipmentId) => {
    deleteShipmentModalHandler.open(shipmentId)
  }

  const openCancelModal = (shipmentId) => {
    cancelShipmentModalHandler.open(shipmentId)
  }

  const createButtonHandler = () => {
    if (isShipment) newShipmentModalHandlers.open()
    else navigate('/panel/quotations/create')
  }

  const Table = useCallback(
    ({ children }) => (
      <RoyanTable
        fixFirstColumn={true}
        sortOrder="DESC"
        name={entity + 's-list'}
        sortKey="creation_date"
        request={(params) => getShipments(isShipment ? 'dossiers' : 'quotes', params)}
        filters={shipmentsFilters}
        onRowDoubleClick={handleDoubleClick}
        filterFields={<ShipmentsFilterFields entity={entity} />}
        columns={generateShipmentsColumns(
          isWarehouseUser,
          openDeleteModal,
          openCancelModal,
          currentUser,
          entity
        )}
        onExportSuccess={() => setIsExportExcel(true)}
        exports={[
          !isShipment && {
            title: 'Performance report',
            request: (filters, columns) => reportPerformance({ filters }),
          },
          {
            title: 'Export as .xlsx',
            request: (filters, columns) => exportData({ table: 'shipments', filters, columns }),
          },
        ]}
      >
        {children}
      </RoyanTable>
    ),
    [entity]
  )

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text={`${isShipment ? 'Operation' : 'Sales'}`} />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button appearance="primary" onClick={createButtonHandler}>
              New {entity}
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <Table>
        <ShipmentsDeleteModal
          isOpen={isOpenDeleteShipmentModal}
          onClose={deleteShipmentModalHandler.close}
          shipmentId={deleteShipmentModalHandler.data}
          entity={entity}
        />

        <ShipmentsCancelModal
          isOpen={isOpenCancelShipmentModal}
          onClose={cancelShipmentModalHandler.close}
          shipmentId={cancelShipmentModalHandler.data}
          entity={entity}
        />
      </Table>

      <ShipmentCreationSelectTypeModal
        isOpen={isOpenNewShipmentModal}
        onClose={newShipmentModalHandlers.close}
      />
    </>
  )
}

export default ShipmentsListPage
