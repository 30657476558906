// Atlassian
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu'
import DropdownLink from 'components/UI/DropdownLink'

// Interfaces
import { DotsIcon } from '@royan-co/user-interface'

export const generateAirlineColumns = () => {
  return [
    {
      key: 'acronym',
      title: 'IATA code',
      isSortable: true,
      show: true,
      content: (item) => item.acronym,
    },
    {
      key: 'company_name',
      title: 'Name',
      isSortable: true,
      show: true,
      content: (item) => item.company_name,
    },
    {
      key: 'prefix',
      title: 'Prefix',
      isSortable: true,
      show: true,
      content: (item) => item.prefix,
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      content: (item) => (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, ...props }) => (
            <Button ref={triggerRef} appearance="subtle" children={<DotsIcon />} {...props} />
          )}
        >
          <DropdownItemGroup>
            <DropdownLink children="View" to={`/panel/airlines/${item.acronym}`} />
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ]
}
