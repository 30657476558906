import { useMutation, useQueryClient } from '@tanstack/react-query'

// Apis
import { settleInvoice } from './invoice-requests'

// Hooks
import { useUpdateInvoice } from './getInvoice'

// Interfaces
import { useFlags, useRoyanTable } from '@royan-co/user-interface'

const useSettleInvoice = ({ invoiceId, shipmentId }) => {
  const { refresh } = useRoyanTable()
  const queryClient = useQueryClient()
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const { upsertInvoiceProperties } = useUpdateInvoice(invoiceId)

  return useMutation({
    mutationFn: () => settleInvoice(invoiceId),

    onSuccess: (res) => {
      showSuccessFlag(res.message)
      if (shipmentId) {
        queryClient.setQueryData(['shipment', 'invoices', shipmentId], (oldInvoices = {}) =>
          oldInvoices?.map((i) => (i.id_auto === invoiceId ? res.invoice : i))
        )
      } else if (refresh) {
        refresh()
      } else upsertInvoiceProperties(res.invoice)
    },

    onError: (e) => {
      console.error(e)
      showWarningFlag('Something wrong!')
    },
  })
}

export { useSettleInvoice }
