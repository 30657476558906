import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

// Atlaskit
import Drawer from '@atlaskit/drawer'
import TextField from '@atlaskit/textfield'
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { Form, Field } from '@royan-co/user-interface'

const CustomsDeclarationHSCodeDrawer = ({ isOpen, onClose, data, onSubmit }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })
  const isEditing = useMemo(() => data?.single_amount, [data])

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>{isEditing ? 'Edit row' : 'New row'}</h3>

        <Form onSubmit={onSubmit} defaultValues={data}>
          {() => (
            <>
              <Field label="HS code" name="type_of_goods" defaultValue="" isRequired>
                {({ fieldProps }) => <TextField placeholder="" {...fieldProps} />}
              </Field>

              <Field label="No. of collies" name="number_collies" defaultValue="">
                {({ fieldProps }) => <TextField placeholder="" {...fieldProps} />}
              </Field>

              <Field label="Value of goods" name="single_amount" defaultValue="">
                {({ fieldProps }) => <TextField placeholder="" {...fieldProps} />}
              </Field>

              <Field label="Gross weight" name="gross_weight" defaultValue="">
                {({ fieldProps }) => <TextField placeholder="" {...fieldProps} />}
              </Field>

              <Field label="Net weight" name="weight" defaultValue="">
                {({ fieldProps }) => <TextField placeholder="" {...fieldProps} />}
              </Field>

              <LoadingButton type="submit" className="mt-4" shouldFitContainer appearance="primary">
                {isEditing ? 'Edit' : 'Add'}
              </LoadingButton>
              <Button appearance="subtle" className="mt-2" shouldFitContainer onClick={onClose}>
                Cancel
              </Button>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default CustomsDeclarationHSCodeDrawer
