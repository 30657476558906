import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// Apis
import {
  startConsolidationLoading,
  startConsolidationTrip,
  finishConsolidationTrip,
  getConsolidationCargowizPlanner,
  importAirwaybills
} from 'api/consolidations'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Features
import { AccessControl } from 'features/auth'

//Hooks
import useConsolidation from 'hooks/useConsolidation'

//Interfaces
import { ArrowUpIcon, ArrowDownIcon, useFlags, useDisclosure } from '@royan-co/user-interface'

//Atlassian
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu'
import Spinner from '@atlaskit/spinner'

//Components
import ActionCard from 'components/UI/ActionCard/ActionCard'
import ResetLoadingModal from 'components/Consolidations/View/ResetLoadingModal'
import FinishTripModal from 'components/Consolidations/View/FinishTripModal'
import ChangeTakeInChargeDate from 'components/Consolidations/View/ChangeTakeInChargeDate'
import CargowizPlannerAction from 'components/Shipments/View/CargowizPlannerAction'
import { generateConsolidationUserPermissions } from 'components/Consolidations/Create/conslolidation-user-permissions'

const ConsolidationViewActionsPage = () => {
  const navigate = useNavigate()
  const { consolidationId } = useParams()
  const { currentUser } = useAuth()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const [isOpenResetModal, resetModalHandlers] = useDisclosure()
  const [isOpenFinishTripModal, finishTripModalHandlers] = useDisclosure()
  const [isOpenTakeInChargeModal, takeInChargeModalHandlers] = useDisclosure()

  const {
    consolidation,
    startLoadingConsolidation,
    startTripConsolidation,
    finishTripConsolidation,
    updateTakeInChargeDate,
  } = useConsolidation(consolidationId)
  const startLoadingMutation = useMutation(startConsolidationLoading)
  const startTripMutation = useMutation(startConsolidationTrip)
  const finishTripMutation = useMutation(finishConsolidationTrip)
  const importAirwaybillsMutation = useMutation(importAirwaybills)

  const userPermissions = generateConsolidationUserPermissions(consolidation, currentUser)

  const isDisabledDownloadPlanner = useMemo(
    () => consolidation?.shipments_count < 1,
    [consolidation]
  )

  const setStartLoading = () => {
    startLoadingMutation.mutate(consolidationId, {
      onError: (e) => {
        showWarningFlag('Something wrong in start loading.')
        console.error(e)
      },
      onSuccess: (res) => {
        startLoadingConsolidation()
        showSuccessFlag(res?.message)
        navigate(`/panel/consolidations/${consolidationId}/loading-management`)
      },
    })
  }

  const handleStartLoading = () => {
    if (consolidation?.loading === 2) resetModalHandlers.open()
    else if (!consolidation?.loading) {
      setStartLoading()
    } else navigate(`/panel/consolidations/${consolidationId}/loading-management`)
  }

  const handleStartTrip = () => {
    if (consolidation?.trip_started) return

    startTripMutation.mutate(consolidationId, {
      onError: (e) => {
        showWarningFlag('Something wrong in start trip.')
        console.error(e)
      },
      onSuccess: (res) => {
        startTripConsolidation()
        showSuccessFlag(res?.message)
      },
    })
  }

  const handleFinishTrip = () => {
    if (consolidation?.trip_finished) return

    finishTripMutation.mutate(consolidationId, {
      onError: (e) => {
        showWarningFlag('Something wrong in finishing consolidation.')
        console.error(e)
      },
      onSuccess: (res) => {
        finishTripConsolidation()
        showSuccessFlag(res?.message)
        finishTripModalHandlers.close()
      },
    })
  }

  const handleImportAWB = () => {
    importAirwaybillsMutation.mutate(consolidationId, {
      onError: (e) => {
        showWarningFlag('Something wrong in importing.')
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res?.message)
      },
    })
  }

  const onCloseResetModal = () => {
    resetModalHandlers.close()
    startLoadingMutation.reset()
  }

  const onCloseTripFinishModal = () => {
    finishTripModalHandlers.close()
    finishTripMutation.reset()
  }

  const handleCargoCollection = () => {
    navigate(`/panel/consolidations/${consolidationId}/cargo-collection`)
  }

  return (
    <>
      <div className="grid md:grid-cols-3 lg:grid-cols-3 items-start gap-4">
        <ActionCard title="Pre-carriage" icon={<ArrowUpIcon />}>
          <MenuGroup>
            <Section>
              <AccessControl
                resource="consolidations"
                action="changeTakeInCharge"
                userPermissions={userPermissions}
              >
                <ButtonItem onClick={() => takeInChargeModalHandlers.open()}>
                  <div className="flex justify-between">Change take in charge date</div>
                </ButtonItem>
              </AccessControl>

              <AccessControl
                resource="consolidations"
                action="createCargoCollection"
                userPermissions={userPermissions}
              >
                <ButtonItem onClick={handleCargoCollection} shouldTitleWrap>
                  Cargo collection
                </ButtonItem>
              </AccessControl>

              <AccessControl
                resource="consolidations"
                action="viewCargowizPlanner"
                userPermissions={userPermissions}
              >
                <CargowizPlannerAction
                  endpoint={getConsolidationCargowizPlanner}
                  entity={{
                    id: consolidationId,
                    reference: consolidation?.consolidation_code,
                  }}
                  isDisabled={isDisabledDownloadPlanner}
                />
              </AccessControl>

              <ButtonItem onClick={handleStartLoading}>
                <div className="flex justify-between">
                  Start loading
                  {startLoadingMutation.isLoading && <Spinner size="small" />}
                </div>
              </ButtonItem>

              <ButtonItem isDisabled={false} onClick={handleImportAWB}>
                <div className="flex justify-between">
                  Import Airwaybills
                  {importAirwaybillsMutation.isLoading && <Spinner size="small" />}
                </div>
              </ButtonItem>
            </Section>
          </MenuGroup>
        </ActionCard>

        <ActionCard title="Post-carriage" icon={<ArrowDownIcon />}>
          <MenuGroup>
            <Section>
              <AccessControl
                resource="consolidations"
                action="startTrip"
                userPermissions={userPermissions}
              >
                <ButtonItem isDisabled={consolidation.trip_started} onClick={handleStartTrip}>
                  <div className="flex justify-between">
                    Start the trip
                    {startTripMutation.isLoading && <Spinner size="small" />}
                  </div>
                </ButtonItem>
              </AccessControl>

              <AccessControl
                resource="consolidations"
                action="finishTrip"
                userPermissions={userPermissions}
              >
                <ButtonItem
                  isDisabled={consolidation?.trip_finished}
                  onClick={() => finishTripModalHandlers.open()}
                >
                  <div className="flex justify-between">
                    Finish the trip
                    {finishTripMutation.isLoading && <Spinner size="small" />}
                  </div>
                </ButtonItem>
              </AccessControl>
            </Section>
          </MenuGroup>
        </ActionCard>
      </div>

      <ChangeTakeInChargeDate
        isOpen={isOpenTakeInChargeModal}
        onClose={() => takeInChargeModalHandlers.close()}
        consolidation={consolidation}
        update={updateTakeInChargeDate}
      />

      <ResetLoadingModal
        isOpen={isOpenResetModal}
        onClose={onCloseResetModal}
        isError={startLoadingMutation.isError}
        onSubmit={setStartLoading}
        isLoading={startLoadingMutation.isLoading}
        consolidation={consolidation}
      />

      <FinishTripModal
        isOpen={isOpenFinishTripModal}
        onClose={onCloseTripFinishModal}
        isError={finishTripMutation.isError}
        onSubmit={handleFinishTrip}
        isLoading={finishTripMutation.isLoading}
      />
    </>
  )
}

export default ConsolidationViewActionsPage
