import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { saveAs } from 'file-saver'

// API
import { downloadConsolidationPackingList } from 'api/consolidations'

// Hooks
import useConsolidation from 'hooks/useConsolidation'

//Interfaces
import { useFlags } from '@royan-co/user-interface'

// Atlassian
import { LoadingButton } from '@atlaskit/button'

const ConsolidationDownloadPackingList = () => {
  const { consolidationId } = useParams()
  const { showWarningFlag } = useFlags()

  const { consolidation } = useConsolidation(consolidationId, {
    enabled: !!consolidationId,
  })

  const downloadPackingListMutation = useMutation(downloadConsolidationPackingList, {
    onSuccess: (res) => {
      saveAs(new Blob([res]), `Packing list-${consolidation.consolidation_code}.pdf`)
    },
    onError: (e) => {
      showWarningFlag('Something wrong on downloading the packing list. Please retry.')
    },
  })

  return (
    <>
      <LoadingButton
        appearance="default"
        isLoading={downloadPackingListMutation.isLoading}
        onClick={() => downloadPackingListMutation.mutate(consolidation?.id_auto)}
      >
        Packing list
      </LoadingButton>
    </>
  )
}

export default ConsolidationDownloadPackingList
