import { useParams, useNavigate } from 'react-router-dom'

// Atlaskit
import Button from '@atlaskit/button'

// Components
import Card, { CardTitle } from 'components/UI/Card'
import CustomsDeclarationsList from './CustomsDeclarations/List'

const ShipmentViewCargoCustomDeclarations = () => {
  const { shipmentId } = useParams()
  const navigate = useNavigate()

  return (
    <>
      <div className="flex items-center">
        <CardTitle className="mb-3">Custom declarations</CardTitle>

        <Button
          appearance="link"
          spacing="none"
          className="ml-auto"
          onClick={() => navigate(`/panel/shipments/${shipmentId}/customs-declarations/create`)}
        >
          New customs declaration
        </Button>
      </div>

      <Card>
        <CustomsDeclarationsList />
      </Card>
    </>
  )
}

export default ShipmentViewCargoCustomDeclarations
