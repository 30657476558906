import { createContext, useContext, useMemo, useCallback, useState, useEffect } from 'react'

import axios from 'services/Request'

import { useCookie } from '@royan-co/user-interface'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { useNavigate } from 'react-router-dom'

// static data for now
const initialPermissions = [
  {
    resource: 'shipments',
    action: 'duplicate',
    permissionKey: 'canDuplicate',
  },
  {
    resource: 'shipments',
    action: 'delete',
    permissionKey: 'canDelete',
  },
  {
    resource: 'shipments',
    action: 'cancel',
    permissionKey: 'canCancel',
  },
  {
    resource: 'shipments',
    action: 'createMaster',
    permissionKey: 'canCreateMaster',
  },
  {
    resource: 'shipments',
    action: 'accept',
    permissionKey: 'canAccept',
  },
  {
    resource: 'shipments',
    action: 'reject',
    permissionKey: 'canReject',
  },
  {
    resource: 'shipments.share',
    action: 'view',
    permissionKey: 'canView.share',
  },
  {
    resource: 'shipments.share',
    action: 'create',
    permissionKey: 'canCreate.share',
  },
  {
    resource: 'shipments',
    action: 'edit',
    permissionKey: 'canEdit',
  },
  {
    resource: 'shipments',
    action: 'remindPayment',
    permissionKey: 'canRemindPayment',
  },
  {
    resource: 'shipments.cargo',
    action: 'create',
    permissionKey: 'canCreate.cargo',
  },
  {
    resource: 'shipments.cargo',
    action: 'edit',
    permissionKey: 'canEdit.cargo',
  },
  {
    resource: 'shipments.cargo',
    action: 'delete',
    permissionKey: 'canDelete.cargo',
  },
  {
    resource: 'shipments.document',
    action: 'mailDocument',
    permissionKey: 'canMailDocument.document',
  },
  {
    resource: 'consolidations.shipment',
    action: 'delete',
    permissionKey: 'canDelete.shipment',
  },
  { resource: 'shipments.houseShipments', action: 'edit', permissionKey: 'canEdit.houseShipments' },
  {
    resource: 'consolidations',
    action: 'viewCargowizPlanner',
    permissionKey: 'canViewCargowizPlanner',
  },
  {
    resource: 'consolidations',
    action: 'startTrip',
    permissionKey: 'canStartTrip',
  },
  {
    resource: 'consolidations',
    action: 'finishTrip',
    permissionKey: 'canFinishTrip',
  },
  {
    resource: 'consolidations',
    action: 'changeTakeInCharge',
    permissionKey: 'canChangeTakeInCharge',
  },
  {
    resource: 'consolidations',
    action: 'createCargoCollection',
    permissionKey: 'canCreateCargoCollection',
  },
  {
    resource: 'shipments.document',
    action: 'edit',
    permissionKey: 'canEdit.document',
  },
  {
    resource: 'shipments.document',
    action: 'delete',
    permissionKey: 'canDelete.document',
  },
  {
    resource: 'shipments.document',
    action: 'changeVisibility',
    permissionKey: 'canChangeVisibility.document',
  },
  {
    resource: 'shipments.activeInvoices',
    action: 'view',
    permissionKey: 'canView.activeInvoices',
  },
  {
    resource: 'shipments.passiveInvoices',
    action: 'view',
    permissionKey: 'canView.passiveInvoices',
  },
  {
    resource: 'shipments.finance',
    action: 'view',
    permissionKey: 'canView.finance',
  },
  {
    resource: 'shipments',
    action: 'release',
    permissionKey: 'canRelease',
  },
  {
    resource: 'shipments',
    action: 'hold',
    permissionKey: 'canHold',
  },
  {
    resource: 'consolidations.share',
    action: 'view',
    permissionKey: 'canView.share',
  },
  {
    resource: 'consolidations.share',
    action: 'create',
    permissionKey: 'canCreate.share',
  },
  {
    resource: 'consolidations.shipment',
    action: 'edit',
    permissionKey: 'canEdit.shipment',
  },
  {
    resource: 'consolidations',
    action: 'edit',
    permissionKey: 'canEdit',
  },
  {
    resource: 'consolidations',
    action: 'delete',
    permissionKey: 'canDelete',
  },
  {
    resource: 'registries',
    action: 'view',
    permissionKey: 'canView',
  },
  {
    resource: 'airlines',
    action: 'view',
    permissionKey: 'canView',
  },
  {
    resource: 'quotations',
    action: 'view',
    permissionKey: 'canView',
  },
  {
    resource: 'consolidations.trackingUnit',
    action: 'create',
    permissionKey: 'canCreate.trackingUnit',
  },
  {
    resource: 'consolidations.trackingUnit',
    action: 'delete',
    permissionKey: 'canDelete.trackingUnit',
  },
  {
    resource: 'consolidations.document',
    action: 'edit',
    permissionKey: 'canEdit.document',
  },
  {
    resource: 'consolidations.document',
    action: 'delete',
    permissionKey: 'canDelete.document',
  },
  {
    resource: 'consolidations.finance',
    action: 'view',
    permissionKey: 'canView.finance',
  },
]

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [token, setAuthToken, removeAuthToken] = useCookie('auth_token')
  const [isLoadingUser, setIsLoadingUser] = useState(true)
  const [currentUser, setCurrentUser] = useState(null)
  const navigate = useNavigate()

  const domain = useMemo(() => window.location.hostname.split('.').slice(-2).join('.'), [])

  const logout = useCallback(() => {
    removeAuthToken({
      path: '/',
      ...(domain !== 'localhost' && { domain: `.${domain}` }),
    })
  }, [removeAuthToken, domain])

  const getAccountInfo = useCallback(async () => {
    TimeAgo.addDefaultLocale(en)

    try {
      setIsLoadingUser(true)
      const result = await axios.get('/api/user', {
        params: {
          scope: process.env.REACT_APP_API_SCOPE,
        },
      })
      if (!result.permissions.authorized) {
        navigate('/unauthorized')
        setIsLoadingUser(false)
      }
      setCurrentUser(result)
    } catch (error) {
      if (error.response.status === 401) {
        logout()
      }
    } finally {
      setIsLoadingUser(false)
    }
  }, [logout, navigate])

  const isWarehouseUser = useMemo(() => {
    return currentUser?.user_level_name_id === '372d30dd2849813ef6748552539006795YTgCUrQQobv'
  }, [currentUser])

  const isAuthenticated = useMemo(() => {
    return !!token
  }, [token])

  const permissions = useMemo(() => {
    const _permissions = [...initialPermissions]

    if (!isWarehouseUser) {
      _permissions.push(
        ...[
          {
            resource: 'invoices',
            action: 'view',
            permissionKey: 'canView',
          },
          {
            resource: 'airway-bills',
            action: 'view',
            permissionKey: 'canView',
          },
          {
            resource: 'awb-stock',
            action: 'view',
            permissionKey: 'canView',
          },
        ]
      )
    }

    if (['master', 'admin', 'accounting'].includes(currentUser?.user_level_name?.name)) {
      _permissions.push(
        ...[
          {
            resource: 'banks',
            action: 'view',
            permissionKey: 'canView',
          },
          {
            resource: 'payment-terms',
            action: 'view',
            permissionKey: 'canView',
          },
          {
            resource: 'document-types',
            action: 'view',
            permissionKey: 'canView',
          },
          {
            resource: 'currencies',
            action: 'view',
            permissionKey: 'canView',
          },
        ]
      )
    }

    if (['master', 'admin'].includes(currentUser?.user_level_name?.name)) {
      _permissions.push(
        ...[
          {
            resource: 'rates',
            action: 'view',
            permissionKey: 'canView',
          },
          {
            resource: 'margin-calculators',
            action: 'view',
            permissionKey: 'canView',
          },
          {
            resource: 'lines',
            action: 'view',
            permissionKey: 'canView',
          },
          {
            resource: 'service-codes',
            action: 'view',
            permissionKey: 'canView',
          },
          {
            resource: 'users',
            action: 'view',
            permissionKey: 'canView',
          },
          { resource: 'vehicle-types', action: 'view', permissionKey: 'canView' },
        ]
      )
    }

    return _permissions
  }, [currentUser?.user_level_name, isWarehouseUser])

  useEffect(() => {
    async function checkAuth() {
      if (isAuthenticated) {
        if (currentUser) return
        await getAccountInfo()
      }
    }

    checkAuth()
  }, [currentUser, isAuthenticated, getAccountInfo])

  const values = {
    token,
    isAuthenticated,
    setAuthToken,
    logout,
    getAccountInfo,
    isLoadingUser,
    currentUser,
    permissions,
    isWarehouseUser,
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}
export default AuthContext
