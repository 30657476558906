import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'

// API
import { createCustomsDeclaration, editCustomsDeclaration } from './api'

// Hooks
import useShipment from 'hooks/useShipment'
import useCustomDeclaration from './hooks/useCustomDeclaration'

//Interfaces
import { Form, Grid, WarningBannerField, useFlags } from '@royan-co/user-interface'

// Atlaskit
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Spinner from '@atlaskit/spinner'
import Button, { LoadingButton } from '@atlaskit/button'
import SectionMessage from '@atlaskit/section-message'

// Components
import CustomsDeclarationGeneralSection from './components/Create/GeneralSection'
import CustomsDeclarationHSCodes from './components/Create/HSCodes'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { getFilled } from 'utils/helpers'

const CustomsDeclarationCreateEditPage = () => {
  const { shipmentId, declarationId } = useParams()
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const pageTitle = (declarationId ? 'Edit' : 'New') + ' customs declaration'

  const {
    shipment,
    refetch,
    isError,
    isLoading: isLoadingShipment,
    isFetching,
  } = useShipment(shipmentId)

  const {
    declaration,
    isLoading: isLoadingDeclaration,
    isFetching: isFetchingDeclaration,
    isError: isErrorDeclaration,
    refetch: refetchDeclaration,
  } = useCustomDeclaration(shipmentId, declarationId, {
    enabled: !!declarationId,
  })

  const declarationCreationMutation = useMutation(
    !!declarationId
      ? editCustomsDeclaration.bind(null, shipmentId, declarationId)
      : createCustomsDeclaration.bind(null, shipmentId)
  )

  const isLoadingData = useMemo(
    () => (!!declarationId ? isLoadingDeclaration : isLoadingShipment),
    [declarationId, isLoadingShipment, isLoadingDeclaration]
  )

  const isFetchingData = useMemo(
    () => (!!declarationId ? isFetchingDeclaration : isFetching),
    [declarationId, isFetching, isFetchingDeclaration]
  )

  const isErrorData = useMemo(
    () => (!!declarationId ? isErrorDeclaration : isError),
    [declarationId, isError, isErrorDeclaration]
  )

  const refetchData = useMemo(
    () => (!!declarationId ? refetchDeclaration : refetch),
    [declarationId, refetch, refetchDeclaration]
  )

  const defaultValues = {
    type: parseInt(getFilled(declaration, 'type', '')),
    mrn: getFilled(declaration, 'mrn', ''),
    codes: [],
  }

  const onSubmit = (data, { setError }) => {
    declarationCreationMutation.mutate(data, {
      onError: (e) => {
        console.error(e)
        handleErrorOnSubmit(e, setError, data)
        showWarningFlag(e?.response?.data?.message || 'Something went wrong.')
      },
      onSuccess: (res) => {
        showSuccessFlag(res?.message)
        queryClient.setQueryData(
          ['shipment', 'customs-declarations', shipmentId],
          (declarations = []) => {
            if (!!declarationId) {
              if (!declarations.length) return
              return declarations?.map((i) => (i.id === declarationId ? res.declaration : i))
            } else {
              return [...declarations, res?.declaration]
            }
          }
        )

        navigate(`/panel/shipments/${shipmentId}/cargo-details`)
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={8} xl={6} offsetMd={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Operation" />
                <BreadcrumbsItem text="Shipments" to="/panel/shipments" component={Link} />
                <BreadcrumbsItem
                  text={isLoadingShipment ? <Spinner size="xsmall" /> : shipment?.reference}
                  to={`/panel/shipments/${shipment?.id_auto}`}
                  component={Link}
                />
                <BreadcrumbsItem
                  text="Cargo details"
                  to={`/panel/shipments/${shipment?.id_auto}/cargo-details`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          {isLoadingData || isFetchingData ? (
            <div className="text-center my-8">
              <Spinner />
            </div>
          ) : isErrorData ? (
            <Grid gutter={24} className="w-full">
              <Grid.Col md={6} offsetMd={3}>
                <SectionMessage
                  appearance="warning"
                  title="Something wrong on loading record data, please retry"
                >
                  <Button onClick={() => refetchData()}>Retry</Button>
                </SectionMessage>
              </Grid.Col>
            </Grid>
          ) : (
            <Form onSubmit={onSubmit} defaultValues={defaultValues}>
              {() => (
                <>
                  <CustomsDeclarationGeneralSection />

                  <CustomsDeclarationHSCodes />

                  <div className="mt-6 text-right">
                    <LoadingButton
                      type="submit"
                      appearance="primary"
                      isLoading={declarationCreationMutation?.isLoading}
                    >
                      {declarationId ? 'Update' : 'Create'} declaration
                    </LoadingButton>
                  </div>
                </>
              )}
            </Form>
          )}
        </Grid.Col>
      </Grid>
    </>
  )
}

export default CustomsDeclarationCreateEditPage
