import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams, useLocation, Outlet, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// API
import { addGroundHandler } from '../api'

// Hooks
import { useAirline } from '../hooks/useAirline'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Tabs, { Tab, TabList } from '@atlaskit/tabs'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

//Interfaces
import {
  useRoyanTableLastFilters,
  Skeleton,
  useDisclosure,
  useFlags,
} from '@royan-co/user-interface'
import GroundHandlerDrawer from '../components/View/GroundHandlerDrawer'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'

const staticTabs = [
  { title: 'General information', path: '' },
  { title: 'Ground handlers', path: '/ground-handlers' },
]

const AirlineViewPage = () => {
  const { airlineId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()

  const [isOpenGHDrawer, GHDrawerHandler] = useDisclosure('ground-handler')

  const { data: airline, isLoading, isError, refetch, invalidate } = useAirline(airlineId)

  const pageTitle = useMemo(() => airline?.company_name, [airline])

  const addGroundHandlerMutation = useMutation(
    addGroundHandler.bind(null, GHDrawerHandler.data?.acronym)
  )

  const { lastUrlSearch } = useRoyanTableLastFilters('airlines-list')

  const tabs = useMemo(
    () =>
      staticTabs.map((tab) => ({
        ...tab,
        path: `/panel/airlines/${airlineId}${tab.path}`,
      })),
    [airline]
  )

  const selectedTabIndex = useMemo(
    () => tabs.findIndex((t) => t.path === pathname),
    [pathname, tabs]
  )

  const navigateToTab = (index) => {
    const tab = tabs[index]

    navigate(tab.path)
  }

  const SelectedTabButton = useMemo(() => {
    if (selectedTabIndex === 1) {
      console.log(airline)
      return (
        <Button appearance="primary" onClick={() => GHDrawerHandler.open({ ...airline })}>
          Add a ground handler
        </Button>
      )
    }
  }, [airline, selectedTabIndex])

  const onSubmitGroundHandler = (data, { setError }) => {
    addGroundHandlerMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        invalidate()
        showSuccessFlag(res.message)
        onCloseDrawer()
      },
    })
  }

  const onCloseDrawer = () => {
    GHDrawerHandler.close()
    addGroundHandlerMutation.reset()
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Airlines" to="/panel/airlines" component={Link} />
            <BreadcrumbsItem text={airline?.acronym} />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            {SelectedTabButton}
            <Button
              component={Link}
              appearance="default"
              to={`/panel/airlines${lastUrlSearch ? `?${lastUrlSearch}` : ''}`}
            >
              Back
            </Button>
          </ButtonGroup>
        }
      >
        {isLoading ? (
          <Skeleton height={32} maw={320} />
        ) : (
          <div className="flex items-center">{pageTitle}</div>
        )}
      </PageHeader>

      <Tabs onChange={navigateToTab} selected={selectedTabIndex}>
        <TabList>
          {tabs.map((tab, i) => (
            <Tab key={i} on>
              {tab.title}
            </Tab>
          ))}
        </TabList>
      </Tabs>

      <div className="px-2 py-4">
        {isLoading ? (
          <div className="flex justify-center my-32">
            <Spinner />
          </div>
        ) : isError ? (
          <SectionMessage appearance="warning" title="Something went wrong. Please retry.">
            <Button onClick={() => refetch()}>Retry</Button>
          </SectionMessage>
        ) : (
          selectedTabIndex > -1 && <Outlet />
        )}
      </div>

      <GroundHandlerDrawer
        isOpen={isOpenGHDrawer}
        onClose={onCloseDrawer}
        onSubmit={onSubmitGroundHandler}
        isLoading={addGroundHandlerMutation.isLoading}
      />
    </>
  )
}

export default AirlineViewPage
