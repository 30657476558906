import { useEffect } from 'react'
import { getFilled, transformSelectField, findOption } from 'utils/helpers'
import {
  registryEntityTypeOptions,
  registryClassificationOptions,
  registryFreightForwarderOptions,
  registryClientTypeOptions,
  registryTypeOptions,
} from '../constants'

//Contexts
import { useRegistryCreationContext } from 'contexts/RegistryCreationProvider'

//Services
import usePaymentBanks from 'hooks/usePaymentBanks'
import usePaymentConditions from 'hooks/usePaymentConditions'

//Atlassian
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'

//Interfaces
import { Field, Grid, useFormContext, useFormValues } from '@royan-co/user-interface'

const RegistryCreationGeneralInformationFields = ({ creator, accessToEditCode }) => {
  const { setValue } = useFormContext()
  const { type, freight_forwarder } = useFormValues()
  const { identicalFields } = useRegistryCreationContext()
  const { paymentBankOptions, isLoadingPaymentBanks } = usePaymentBanks()
  const { paymentConditionOptions, isLoadingPaymentConditions } = usePaymentConditions()

  useEffect(() => {
    setValue('type', getFilled(creator, 'type', null))
    setValue('list_type', getFilled(creator, 'list_type', null))
    setValue('client_type', getFilled(creator, 'client_type', null))
    setValue('payment_code', getFilled(creator, 'payment_code', null))
  }, [creator, setValue])

  return (
    <div className="shadow rounded p-6">
      <Field
        isRequired
        label="Name"
        defaultValue=""
        className="!mt-0"
        name="company_name"
        validate={() =>
          identicalFields?.includes('company_name') ? `This Name already exists.` : true
        }
      >
        {({ fieldProps, error }) => (
          <>
            <Textfield placeholder="Type a name" {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      {accessToEditCode && (
        <Field label="Code" name="numerator_code" className="!mt-4">
          {({ fieldProps, error }) => (
            <>
              <Textfield placeholder="Type a code" {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      )}

      <Grid className="!mt-1">
        <Grid.Col sm={type === 0 ? 6 : 12}>
          <Field
            isRequired
            label="Type"
            type="select"
            name="type"
            transform={{
              output: (opt) => (opt?.value !== undefined ? opt.value : null),
              input: (v) => findOption(registryTypeOptions, v),
            }}
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                options={registryTypeOptions}
                placeholder="Choose a type"
                {...fieldProps}
              />
            )}
          </Field>
        </Grid.Col>

        {type === 0 && (
          <Grid.Col sm={6}>
            <Field
              isRequired
              type="select"
              label="Client type"
              name="client_type"
              transform={{
                output: (opt) => (opt?.value !== undefined ? opt.value : null),
                input: (v) => findOption(registryClientTypeOptions, v),
              }}
            >
              {({ fieldProps }) => (
                <Select
                  isClearable={true}
                  options={registryClientTypeOptions}
                  placeholder="Choose a clinet type"
                  {...fieldProps}
                />
              )}
            </Field>
          </Grid.Col>
        )}
      </Grid>

      <Field
        isRequired
        type="select"
        className="!mt-4"
        name="entity_type"
        label="Entity type"
        transform={transformSelectField(registryEntityTypeOptions)}
        validate={() => (identicalFields?.entity_type ? `This Entity type already exists.` : true)}
      >
        {({ fieldProps, error }) => (
          <>
            <Select options={registryEntityTypeOptions} placeholder="Choose" {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <Field
        isRequired
        type="select"
        className="!mt-4"
        name="list_type"
        label="Classification"
        transform={transformSelectField(registryClassificationOptions)}
      >
        {({ fieldProps, error }) => (
          <>
            <Select options={registryClassificationOptions} placeholder="Choose" {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <Grid className="!mt-1">
        <Grid.Col sm={freight_forwarder === 1 ? 6 : 12}>
          <Field
            isRequired
            type="select"
            name="freight_forwarder"
            label="Freight forwarder"
            transform={transformSelectField(registryFreightForwarderOptions)}
            validate={() =>
              identicalFields?.freight_forwarder ? `This Freight forwarder already exists.` : true
            }
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  options={registryFreightForwarderOptions}
                  placeholder="Choose"
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>

        {freight_forwarder === 1 && (
          <>
            <Grid.Col sm={6}>
              <Field defaultValue="" name="f_f_number" label="Freight forwarder number">
                {({ fieldProps, error }) => (
                  <>
                    <Textfield placeholder="Type the freight forwarder number" {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </Grid.Col>

            <Grid.Col sm={6}>
              <Field defaultValue="" name="iata_code" className="!mt-0" label="IATA Code">
                {({ fieldProps, error }) => (
                  <>
                    <Textfield placeholder="Type the IATA Code" {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </Grid.Col>

            <Grid.Col sm={6}>
              <Field
                defaultValue=""
                name="carrier_r_number"
                className="!mt-0"
                label="Carrier registration no."
              >
                {({ fieldProps, error }) => (
                  <>
                    <Textfield placeholder="Type the carrier registration no." {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </Grid.Col>
          </>
        )}
      </Grid>

      <Grid>
        <Grid.Col sm={6}>
          <Field label="Website" name="website" className="!mt-4" defaultValue="">
            {({ fieldProps, error }) => (
              <>
                <Textfield placeholder="Type the website" {...fieldProps} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>

        <Grid.Col sm={6}>
          <Field label="GHA code" name="gha_code" className="!mt-4" defaultValue="">
            {({ fieldProps, error }) => (
              <>
                <Textfield placeholder="Type the code" {...fieldProps} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>
      </Grid>

      <hr className="border-neutral-40 my-8" />

      <Field
        defaultValue=""
        className="!mt-4"
        name="vat_number"
        label="VAT number"
        validate={() => (identicalFields?.vat_number ? `This VAT number already exists.` : true)}
      >
        {({ fieldProps, error }) => (
          <>
            <Textfield placeholder="Type the VAT number" {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <Grid className="mt-0">
        <Grid.Col sm={6}>
          <Field
            type="select"
            name="payment_code"
            label="Payment condition"
            transform={transformSelectField(paymentConditionOptions)}
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  placeholder="Choose"
                  options={paymentConditionOptions}
                  isLoading={isLoadingPaymentConditions}
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>

        <Grid.Col sm={6}>
          <Field
            name="bank"
            label="Bank"
            type="select"
            transform={transformSelectField(paymentBankOptions)}
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  placeholder="Choose"
                  options={paymentBankOptions}
                  isLoading={isLoadingPaymentBanks}
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>
      </Grid>

      <Grid className="mt-0">
        <Grid.Col sm={6}>
          <Field defaultValue="" label="Fiscal code" name="fiscal_code">
            {({ fieldProps, error }) => (
              <>
                <Textfield placeholder="Type the fiscal code" {...fieldProps} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>

        <Grid.Col sm={6}>
          <Field defaultValue="" label="PEC" name="pec_invoicing">
            {({ fieldProps, error }) => (
              <>
                <Textfield placeholder="Type the fiscal code" {...fieldProps} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col sm={6}>
          <Field defaultValue="" label="Swift no." name="swift">
            {({ fieldProps, error }) => (
              <>
                <Textfield placeholder="Type the swift no." {...fieldProps} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>
        <Grid.Col sm={6}>
          <Field defaultValue="" label="Share capital" name="share_capital">
            {({ fieldProps, error }) => (
              <>
                <Textfield
                  placeholder="Type the share capital"
                  {...fieldProps}
                  elemAfterInput={
                    <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                      Euro (€)
                    </div>
                  }
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>
      </Grid>
    </div>
  )
}

export default RegistryCreationGeneralInformationFields
