import { useQuery, useQueryClient } from '@tanstack/react-query'

// API
import { getCustomsDeclaration } from '../api'

const useCustomDeclaration = (shipmentId, declarationId, { enabled = true } = {}) => {
  const queryClient = useQueryClient()

  const {
    data: declaration,
    isFetching,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['customs-declaration', declarationId],
    () => getCustomsDeclaration(shipmentId, declarationId),
    {
      staleTime: Infinity,
      enabled,
    }
  )

  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: ['customs-declaration', declarationId],
      refetchType: 'none',
    })
  }

  return { declaration, isLoading, isFetching, isError, refetch, invalidate }
}

export default useCustomDeclaration
