import { useMemo } from 'react'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Hooks
import { useSettleInvoice } from 'features/invoice/api/settleInvoice'

// Components
import ActionDropdownItem from 'components/ActionDropdownItem'
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const SettleInvoiceAction = ({ invoice, shipmentId }) => {
  const { currentUser } = useAuth()
  const settleInvoiceMutation = useSettleInvoice({
    invoiceId: invoice?.id_auto,
    shipmentId,
  })

  const accessibility = useMemo(() => {
    if (!['master', 'admin', 'accounting'].includes(currentUser?.user_level_name?.name)) {
      return {
        access: false,
        message: 'You do not have access to settle the invoice.',
      }
    } else if (invoice?.is_payd === 3)
      return {
        access: false,
        message: 'Invoice is already settled',
      }
    else
      return {
        access: true,
      }
  }, [currentUser?.user_level_name?.name, invoice?.is_payd])

  return invoice?.id_auto ? (
    <DisabledElementTooltip
      isDisabled={!accessibility?.access}
      tooltipContent={accessibility?.message}
    >
      <ActionDropdownItem
        label="Settle"
        isDisabled={!accessibility?.access}
        mutation={settleInvoiceMutation}
      />
    </DisabledElementTooltip>
  ) : null
}

export default SettleInvoiceAction
