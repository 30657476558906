import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Hooks
import { useAirline } from 'features/airlines/hooks/useAirline'

// Atlassian
import EmptyState from '@atlaskit/empty-state'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

//Components
import Card from 'components/UI/Card'

const heads = { cells: [{ content: 'Airport' }, { content: 'Handler' }, { content: 'Code' }] }

const AirlineGroundHandlersTab = () => {
  const { airlineId } = useParams()

  const { data: airline } = useAirline(airlineId)

  const rows = useMemo(() => {
    return airline?.handlers?.map((item, i) => {
      let items = [
        {
          id: `airport${i}`,
          content: item?.pivot?.airport,
        },
        {
          id: `handler${i}`,
          content: item?.company_name,
        },
        {
          id: `code${i}`,
          content: item?.gha_code,
        },
      ]

      return {
        key: `rows${i}`,
        cells: items,
      }
    })
  }, [airline])

  return (
    <Card>
      {rows?.length < 1 ? (
        <EmptyState header="No ground handlers" />
      ) : (
        <div className="overflow-auto inline-block w-full -mb-6">
          <DynamicTableStateless head={heads} rows={rows} />
        </div>
      )}
    </Card>
  )
}

export default AirlineGroundHandlersTab
