import { Helmet } from 'react-helmet-async'
import { Link, useNavigate } from 'react-router-dom'

// API
import { getAirlines } from '../api'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

// Interfaces
import { RoyanTable } from '@royan-co/user-interface'

// Components
import { airlinesFilters } from '../components/List/filters'
import { generateAirlineColumns } from '../components/List/columns'

const pageTitle = 'Airlines'

const AirlinesListPage = () => {
  const navigate = useNavigate()

  const handleDoubleClick = (e, item) => {
    navigate(`/panel/airlines/${item.acronym}`)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Airlines" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            {/* <Button appearance="primary" component={Link} to="/panel/airlines/create">
              New airline
            </Button> */}
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="airlines-list"
        sortKey="company_name"
        request={getAirlines}
        filters={airlinesFilters}
        onRowDoubleClick={handleDoubleClick}
        columns={generateAirlineColumns()}
      ></RoyanTable>
    </>
  )
}

export default AirlinesListPage
