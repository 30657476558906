import { useQuery } from '@tanstack/react-query'

// API
import { getCustomsDeclarations } from '../api'

const useShipmentCustomsDeclarations = (shipmentId) => {
  const queryKey = ['shipment', 'customs-declarations', shipmentId]

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => getCustomsDeclarations(shipmentId),
    retry: 2,
    staleTime: Infinity,
  })

  return {
    shipmentCustomsDeclarations: data,
    isLoadingCustomsDeclarations: isLoading,
  }
}

export default useShipmentCustomsDeclarations
