import { useMediaQuery } from 'react-responsive'

// Atlassian
import Drawer from '@atlaskit/drawer'
import { LoadingButton } from '@atlaskit/button'

// Interfaces
import { Form } from '@royan-co/user-interface'

// Components
import RatesAirportsField from 'components/Rates/List/AirportsField'
import RegistrySelectField from 'components/Registries/RegistrySelectField'

const GroundHandlerDrawer = ({ isOpen, onClose, onSubmit, isLoading = false }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>Add a ground handler</h3>

        <Form onSubmit={onSubmit}>
          {() => (
            <>
              <RatesAirportsField
                label="Departure airport"
                name="airport"
                spacing="compact"
                isRequired
              />

              <RegistrySelectField
                label="Ground handler"
                name="registry_id"
                registryType="supplier"
                isRequired
              />

              <LoadingButton
                isLoading={isLoading}
                type="submit"
                className="mt-4"
                shouldFitContainer
                appearance="primary"
              >
                Save
              </LoadingButton>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default GroundHandlerDrawer
