import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

//Services
import { addHttp } from 'utils/helpers'
import useRegistry from 'hooks/useRegistry'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

//Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

//Components
import RowTable from 'components/UI/RowTable'
import RegistryTypeTag from 'components/Registries/TypeTag'
import RegistryEntityTypeTag from 'components/Registries/EntityTypeTag'
import RegistryClassificationTag from 'components/Registries/ClassificationTag'
import RegistryFreightForwarderTag from 'components/Registries/FreightForwarderTag'
import RegistryContactsSection from 'components/Registries/View/RegistryContactsSection'
import RegistryBranchesSection from 'components/Registries/View/RegistryBranchesSection'

const RegistryViewGeneralPage = () => {
  const { registryId, creatorId } = useParams()
  const { registry, creator } = useRegistry(registryId, { creatorId })

  const generalRows = useMemo(() => {
    let generalRowsItems = [
      {
        title: 'Company name',
        content: (
          <>
            <div>{registry?.company_name}</div>
            <RegistryTypeTag type={creator?.type} clientType={creator?.client_type} />{' '}
          </>
        ),
      },
      { title: 'Entity type', content: <RegistryEntityTypeTag value={registry?.entity_type} /> },
      {
        title: 'Classification',
        content: <RegistryClassificationTag value={creator?.list_type} />,
      },
      {
        title: 'Freight forwarder',
        content: <RegistryFreightForwarderTag value={registry?.freight_forwarder} />,
      },
    ]

    if (registry?.freight_forwarder === 1) {
      generalRowsItems.push(
        {
          title: 'Freight forwarder number',
          content: registry?.f_f_number || <Lozenge appearance="default">Not set</Lozenge>,
        },
        {
          title: 'IATA code',
          content: registry?.iata_code || <Lozenge appearance="default">Not set</Lozenge>,
        }
      )
    }

    if (registry?.gha_code) {
      generalRowsItems.push({
        title: 'GHA code',
        content: registry?.gha_code,
      })
    }

    if (registry?.carrier_r_number) {
      generalRowsItems.push({
        title: 'Carrier registration no.',
        content: registry?.carrier_r_number,
      })
    }

    if (registry?.website) {
      generalRowsItems.push({
        title: 'Website',
        content: (
          <a href={addHttp(registry?.website)} target="_blank" rel="noreferrer">
            {registry?.website} <ShortcutIcon size={16} />
          </a>
        ),
      })
    }

    return generalRowsItems
  }, [creator, registry])

  return (
    <>
      <div className="shadow rounded p-4">
        <RowTable rows={generalRows} />
      </div>

      <RegistryBranchesSection className="mt-8" />

      <RegistryContactsSection className="mt-8" />
    </>
  )
}

export default RegistryViewGeneralPage
