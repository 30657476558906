import axios from 'services/Request'

export function getAirlines(params) {
  return axios.get('/api/registries/airlines?paginate=1', { params })
}

export function getAirline(airline) {
  return axios.get(`/api/registries/airlines/${airline}`)
}

export function addGroundHandler(airline, params) {
  return axios.post(`/api/registries/airlines/${airline}/handlers`, params)
}
