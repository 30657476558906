//Components
import Card from 'components/UI/Card'

// Atlassian
import TextField from '@atlaskit/textfield'
import Select from '@atlaskit/select'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Field } from '@royan-co/user-interface'

// Utils
import { findOption } from 'utils/helpers'

// Constants
import { shipmentCustomDocumentOptions } from 'components/Shipments/constants'

const CustomsDeclarationGeneralSection = () => {
  return (
    <>
      <Card className="mb-0">
        <Field
          label="Document type"
          type="select"
          name="type"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(shipmentCustomDocumentOptions, v),
          }}
          isRequired
        >
          {({ fieldProps, error }) => (
            <>
              <Select
                isClearable={true}
                options={shipmentCustomDocumentOptions}
                placeholder="Choose"
                {...fieldProps}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field label="MRN" name="mrn" isRequired>
          {({ fieldProps, error }) => (
            <>
              <TextField placeholder="MRN" {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </Card>
    </>
  )
}

export default CustomsDeclarationGeneralSection
