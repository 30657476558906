import axios from 'services/Request'

export function getCustomsDeclarations(shipmentId) {
  return axios.get(`api/shipments/${shipmentId}/customs-declarations`)
}

export function getCustomsDeclaration(shipmentId, declarationId) {
  return axios.get(`api/shipments/${shipmentId}/customs-declarations/${declarationId}`)
}

export function createCustomsDeclaration(shipmentId, params) {
  return axios.post(`api/shipments/${shipmentId}/customs-declarations`, params)
}

export function editCustomsDeclaration(shipmentId, declarationId, params) {
  return axios.put(`api/shipments/${shipmentId}/customs-declarations/${declarationId}`, params)
}

export function deleteCustomsDeclaration(shipmentId, declarationId) {
  return axios.delete(`api/shipments/${shipmentId}/customs-declarations/${declarationId}`)
}
