import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

//Api
import { exportData } from 'api/exports'
import { getAirWaybills } from '../api/airWaybill-requests'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

// Components
import { generateAirWaybillColumns } from '../components/List/columns'
import { airWaybillFilters } from '../components/List/filters'
import AirWaybillsFilterFields from '../components/List/FilterFields'
import AirWaybillDeleteModal from '../components/DeleteModal'

const pageTitle = 'Air waybills'

const AirWaybillListPage = () => {
  const navigate = useNavigate()
  const [isOpenDeleteModal, handleDeleteModal] = useDisclosure('delete')

  const handleDoubleClick = (e, item) => {
    navigate(`/panel/shipments/document/airwayBill/${item.id}`)
  }

  const openDeleteModal = (documentId) => {
    handleDeleteModal.open(documentId)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Operation " />
          </Breadcrumbs>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="airwaybill-list"
        sortKey="creation_date"
        onRowDoubleClick={handleDoubleClick}
        request={getAirWaybills}
        columns={generateAirWaybillColumns(openDeleteModal)}
        filters={airWaybillFilters}
        filterFields={<AirWaybillsFilterFields />}
        searchFieldPlaceholder="Search AWB no."
        exports={[
          {
            title: 'Export as .xlsx',
            request: (filters, columns) => exportData({ table: 'airwaybills', filters, columns }),
          },
        ]}
      >
        <AirWaybillDeleteModal
          isOpen={isOpenDeleteModal}
          onClose={handleDeleteModal.close}
          documentId={handleDeleteModal.data}
        />
      </RoyanTable>
    </>
  )
}

export default AirWaybillListPage
