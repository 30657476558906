import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Hooks
import { useAirline } from 'features/airlines/hooks/useAirline'

// Components
import Card from 'components/UI/Card'
import RowTable from 'components/UI/RowTable'

const AirlineGeneralInformationTab = () => {
  const { airlineId } = useParams()

  const { data: airline } = useAirline(airlineId)

  const rows = useMemo(
    () => [
      {
        title: 'Acronym',
        content: airline?.acronym,
      },
      {
        title: 'Name',
        content: airline?.company_name,
      },
      {
        title: 'Prefix',
        content: airline?.prefix,
      },
    ],
    [airline]
  )

  return (
    <Card className="mb-6">
      <RowTable rows={rows} />
    </Card>
  )
}

export default AirlineGeneralInformationTab
