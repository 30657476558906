import { useQuery, useQueryClient } from '@tanstack/react-query'

// API
import { getAirline } from '../api'

export const useAirline = (airlineId, { enabled = true } = {}) => {
  const queryClient = useQueryClient()

  const { data, isFetching, isLoading, isError, refetch } = useQuery(
    ['airline', airlineId],
    () => getAirline(airlineId),
    {
      staleTime: Infinity,
      enabled: enabled,
    }
  )

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['airline', airlineId] })
  }

  return {
    data,
    isLoading,
    isFetching,
    isError,
    refetch,
    invalidate,
  }
}
