import { useMemo, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

// Apis
import { editShipmentParam } from 'api/shipments'

// Hooks
import useShipment from 'hooks/useShipment'

// Atlaskit
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'

//Components
import Card, { CardTitle } from 'components/UI/Card'
import RowTable from 'components/UI/RowTable'
import ShipmentTermsOfDeliveryInlineEdit from './InvoiceInformation/TermsOfDelivery'
import ShipmentTermsOfPaymentInlineEdit from './InvoiceInformation/TermsOfPayment'
import CommercialInvoicesList from './CommercialInvoice/List'
import ShipmentTextInlineEdit from './GeneralDetails/Text'

const ShipmentViewCargoInvoiceInformation = () => {
  const { shipmentId } = useParams()
  const queryClient = useQueryClient()
  const { shipment } = useShipment()
  const navigate = useNavigate()

  const changeDeliveryTermFn = useCallback(
    async (data) => {
      try {
        const res = await editShipmentParam(shipmentId, 'terms_delivery', {
          terms_delivery: data.value,
        })

        return res
      } catch (e) {
        throw e
      }
    },
    [shipmentId]
  )

  const changePaymentTermFn = useCallback(
    async (data) => {
      try {
        const res = await editShipmentParam(shipmentId, 'terms_payment', {
          terms_payment: data.value,
        })

        return res
      } catch (e) {
        throw e
      }
    },
    [shipmentId]
  )

  const changeDeliveryTermCallback = useCallback(
    (res) => {
      queryClient.setQueryData(['shipment', shipmentId], (oldShipment) => ({
        ...oldShipment,
        terms_delivery: res.shipment.terms_delivery,
      }))
    },
    [queryClient, shipmentId]
  )

  const changePaymentTermCallback = useCallback(
    (res) => {
      queryClient.setQueryData(['shipment', shipmentId], (oldShipment) => ({
        ...oldShipment,
        terms_payment: res.shipment.terms_payment,
      }))
    },
    [queryClient, shipmentId]
  )

  const rows = useMemo(() => {
    let items = [
      {
        title: 'Terms of delivery',
        content: (
          <ShipmentTermsOfDeliveryInlineEdit
            term={shipment?.terms_delivery}
            canEdit={true}
            changeDeliveryTermFn={changeDeliveryTermFn}
            changeDeliveryTermCallback={changeDeliveryTermCallback}
          />
        ),
      },
      {
        title: 'Terms of payment',
        content: (
          <ShipmentTermsOfPaymentInlineEdit
            term={shipment?.terms_payment}
            canEdit={true}
            changePaymentTermFn={changePaymentTermFn}
            changePaymentTermCallback={changePaymentTermCallback}
          />
        ),
      },
      {
        title: 'Goods value',
        content: (
          <ShipmentTextInlineEdit
            canEdit={!shipment?.commercial_invoices_count}
            shipmentId={shipmentId}
            attribute="goods_value"
            value={shipment?.goods_value}
          />
        ),
      },
      {
        title: 'Description',
        content: (
          <ShipmentTextInlineEdit
            canEdit={true}
            shipmentId={shipmentId}
            attribute="description"
            value={shipment?.description}
            type="TextArea"
          />
        ),
      },
    ]

    return items
  }, [
    shipment,
    shipmentId,
    changeDeliveryTermCallback,
    changeDeliveryTermFn,
    changePaymentTermCallback,
    changePaymentTermFn,
  ])

  return (
    <>
      <div className="flex items-center">
        <CardTitle className="mb-3">Invoice information</CardTitle>

        <Button
          appearance="link"
          spacing="none"
          className="ml-auto"
          onClick={() => navigate(`/panel/shipments/${shipmentId}/commercial-invoices/create`)}
        >
          New commercial invoice
        </Button>
      </div>

      <Card>
        <CommercialInvoicesList />
        <RowTable rows={rows} width="w-1/2" />
      </Card>
    </>
  )
}

export default ShipmentViewCargoInvoiceInformation
