import { useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// API
import { deleteCustomsDeclaration } from 'features/shipments/CustomsDeclaration/api'

// Hooks
import useShipmentCustomsDeclarations from 'features/shipments/CustomsDeclaration/hooks/useShipmentCustomsDeclarations'

// Atlaskit
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

// Interfaces
import { useDisclosure, useFlags, EditIcon, TrashIcon } from '@royan-co/user-interface'

// Constants
import { shipmentCustomDocumentOptions } from 'components/Shipments/constants'

// Utils
import { findOption } from 'utils/helpers'

// Components
import DeleteCustomsDeclarationModal from 'features/shipments/CustomsDeclaration/components/List/DeleteModal'

const tableHeads = {
  cells: [{ content: 'Type' }, { content: 'MRN' }, { content: '' }],
}

const CustomsDeclarationsList = () => {
  const { shipmentId } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { showSuccessFlag } = useFlags()

  const [openDeleteDeclarationModal, deleteDeclarationModalHandler] = useDisclosure('delete')

  const deleteDeclarationMutation = useMutation(deleteCustomsDeclaration.bind(null, shipmentId))

  const { shipmentCustomsDeclarations, isLoadingCustomsDeclarations } =
    useShipmentCustomsDeclarations(shipmentId)

  const tableRows = useMemo(() => {
    return shipmentCustomsDeclarations?.map((row, i) => {
      let items = [
        {
          content: findOption(shipmentCustomDocumentOptions, parseInt(row?.type))?.label,
        },
        {
          content: row?.mrn,
        },
        {
          content: (
            <div className="flex justify-end">
              <Button
                spacing="compact"
                appearance="subtle"
                iconBefore={<EditIcon />}
                onClick={() =>
                  navigate(`/panel/shipments/${shipmentId}/customs-declarations/${row?.id}/edit`)
                }
              />

              <Button
                spacing="compact"
                appearance="subtle"
                iconBefore={<TrashIcon className="text-red-400" />}
                onClick={() => deleteDeclarationModalHandler.open(row)}
              />
            </div>
          ),
        },
      ]

      return {
        key: i,
        cells: items,
      }
    })
  }, [shipmentCustomsDeclarations])

  const handleDeleteDeclaration = () => {
    const declarationId = deleteDeclarationModalHandler?.data?.id

    deleteDeclarationMutation.mutate(declarationId, {
      onSuccess: (res) => {
        queryClient.setQueryData(['shipment', 'customs-declarations', shipmentId], (declarations) =>
          declarations.filter((i) => i.id !== declarationId)
        )
        showSuccessFlag(res?.message)
        deleteDeclarationModalHandler.close()
      },
    })
  }

  const closeDeleteModal = () => {
    deleteDeclarationMutation.reset()
    deleteDeclarationModalHandler.close()
  }

  return (
    <>
      {isLoadingCustomsDeclarations ? (
        <div className="w-full text-center my-2">
          <Spinner />
        </div>
      ) : (
        <div className="inline-block w-full -mb-6">
          <DynamicTableStateless head={tableHeads} rows={tableRows} />
        </div>
      )}

      <DeleteCustomsDeclarationModal
        isOpen={openDeleteDeclarationModal}
        onClose={closeDeleteModal}
        onSubmit={handleDeleteDeclaration}
        isError={deleteDeclarationMutation.isError}
        isLoading={deleteDeclarationMutation.isLoading}
      />
    </>
  )
}

export default CustomsDeclarationsList
